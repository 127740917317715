import { Link } from "gatsby"
import React from "react"
import Layout from '../components/layout';
import { graphql } from 'gatsby';

const BankListsPage = ({ data }) => {
	const seoData = {
		'title' : 'Top banks stores categories combinations lists', 
		'description' : 'Top banks stores categories combinations lists', 
		'canonicalUrl' : '/bank-lists-pages/', 
		'robots' : 'index,follow'
		};
	
	return(
  <Layout page={seoData} >
    <section className="container all-list-wrapper bank-list-page">
      <h1>Top banks offers list pages</h1>
      <div>
        {data.hc.lists.map(list => (
          <h2 key={list.id}> <Link to={list.page.url}>{list.page.h1}</Link></h2>
        ))}
      </div>
    </section>
  </Layout>
)
}

export default BankListsPage

export const bankLists = graphql`
  query {
    hc {
      lists (where : {OR : [{ disabled : false}, {disabled : null}]}) {
        id
		page {h1 url}
       }
    }
  }
`;
